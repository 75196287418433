import { render, staticRenderFns } from "./CarouselPause.vue?vue&type=template&id=3a2e1e7a"
import script from "./CarouselPause.vue?vue&type=script&lang=js"
export * from "./CarouselPause.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports